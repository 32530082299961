<template>
  <div>
    <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/lunbo1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img
            src="../../assets/F2CE8085-E4E7-4214-8473-6D61924D1A0D.png"
            alt=""
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            src="../../assets/31F3B990-F1D3-415c-8AAD-E85731C33CA1.png"
            alt=""
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="my-indexlist">
      <van-grid class="my-indexlists" :border="false" :column-num="4">
        <!--  -->
        <van-grid-item to="/guanghe/brief">
          <img src="../../assets/yeguang.png" alt="" />
          <span>小站简介</span>
        </van-grid-item>
        <van-grid-item to="/guanghe/demand">
          <img src="../../assets/xuqiu.png" alt="" />
          <span>需求清单</span>
        </van-grid-item>
        <van-grid-item to="/guanghe/resource">
          <img src="../../assets/ziyuan.png" alt="" />
          <span>资源清单</span>
        </van-grid-item>
        <van-grid-item to="/guanghe/project">
          <img src="../../assets/xiangmu.png" alt="" />
          <span>项目清单</span>
        </van-grid-item>
      </van-grid>
      <div
        style="
          border-top: 10px solid #f1f3f4;
          padding: 10px 0px;
          border-bottom: 10px solid #f1f3f4;
        "
      >
        <div class="my-box">
          <router-link to="/guanghe/wantfrom">
            <div>
              <img src="../../assets/xinyuan.png" alt="" />
              <span> 个性化点单服务 </span>
            </div>
          </router-link>
        </div>
        <div class="my-box">
          <router-link to="/guanghe/bianmin">
            <div>
              <img src="../../assets/zhiyuan.png" alt="" />
              <span> 志愿接单服务 </span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- <div>
      <div
        style="
          text-align: center;
          color: red;
          font-weight: 700;
          margin: 15px;
          font-size: 17px;
        "
      >
        预约办事
      </div>
      <div class="makebox">
        <router-link :to="'/guanghe/detail/' + 1">
        <div style="border-right: 1px solid #f4f3f3;">
          <span>办理居住证证明</span><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div>
          <span>办理旅游年票证明</span><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div style="border-right: 1px solid #f4f3f3;">
          <span style="line-height:20px">残疾人免费乘坐公交车爱心卡办理</span
          ><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div>
          <span>医疗、养老保险办理</span
          ><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div style="border-right: 1px solid #f4f3f3;"><span>体检服务</span><img src="../../assets/xf.jpg" alt="" /></div>
        <div>
          <span>计生事项办理</span><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div style="border-right: 1px solid #f4f3f3;">
          <span>心理咨询服务</span><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div><span>法律服务</span><img src="../../assets/xf.jpg" alt="" /></div>
        </router-link>
      </div>
    </div> -->
    <div>
      <div
        style="
          text-align: center;
          color: red;
          font-weight: 700;
          margin: 15px;
          font-size: 17px;
        "
      >
        党员风采
      </div>
      <div>
        <img src="../../assets/dangshi.png" style="width: 100%" alt="" />
        <div style="position: relative">
          <div style="display: inline-block; margin-left: 15px">
            <img style="width: 100%" src="../../assets/dang1.png" alt="" />
          </div>
          <div style="display: inline-block; position: absolute; top: 70px">
            <div style="color: #de2910; margin: 10px 0px">党员榜样</div>
            <div style="color: #de2910">敬业奉献</div>
          </div>
        </div>
        <div style="position: relative">
          <div style="display: inline-block; margin-left: 15px">
            <img style="width: 100%" src="../../assets/dang2.png" alt="" />
          </div>
          <div style="display: inline-block; position: absolute; top: 70px">
            <div style="color: #de2910; margin: 10px 0px">党员榜样</div>
            <div style="color: #de2910">助人为乐</div>
          </div>
        </div>
        <div style="position: relative">
          <div style="display: inline-block; margin-left: 15px">
            <img style="width: 100%" src="../../assets/dang3.png" alt="" />
          </div>
          <div style="display: inline-block; position: absolute; top: 70px">
            <div style="color: #de2910; margin: 10px 0px">党员榜样</div>
            <div style="color: #de2910">爱岗敬业</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created(){
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
}
.my-swipe img {
  width: 100%;
}
.my-indexlist {
  font-size: 14px;
}
.my-indexlists {
}
.my-indexlists img {
  width: 50%;
  margin-bottom: 10px;
}
.my-box {
  display: inline-block;
  width: 40%;
  height: 50px;
  margin: 5px 5%;
  position: relative;
}
.my-box img {
  width: 30%;
}
.my-box span {
  position: absolute;
  top: 15px;
  right: 0px;
  color: #000;
}
/* .jianjie {
  padding-bottom: 20px;
  border-top: 10px solid #f8f5f5;
  border-bottom: 10px solid #f8f5f5;
}
.jianjie div {
  display: inline-block;
}
.jianjieA {
  display: inline-block;
  width: 40%;
}
.jianjieA img {
  width: 100%;
} */
.makebox div {
  display: inline-block;
  position: relative;
  width: 45%;
  height: 40px;
  padding: 10px 8px;
  line-height: 40px;
  color: #333;
  border-top: 1px solid #f4f3f3;
  /* border: 1px solid #000; */
}
.makebox span {
  width: 80%;
  font-size: 14px;
  float: left;
}
.makebox img {
  position: absolute;
  top: 10px;
  float: right;
  width: 20%;
}
</style>